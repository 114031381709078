const UvjetiKoristenja = ({
    modalTitle,
    generateHeaderCloseIco
}) => {

    const uvjetiContent = () => {
        return (
            <div className="welcome-msg">
                <div className="row margin-bottom">
                    <div>
                        <div className="row middle margin-bottom">
                            <img src="/ivanko.jpg" className="avatar-img my-img" />
                            <h3><em>Dobrodošli na nethr.chat!<span className="block">Community chat, play, publish & date!</span></em></h3>
                        </div>
                        <p><em>Ja sam Ivanko Perišić. Radim web stranice i web/mobilne aplikacije koje se kreću od ekonomične prema premium razini. Nudim izradu, smještaj i održavanje u jednom. Cilj mi je dugoročna suradnja i preporuke. Radim za top digitalne agencije u RH i EU. Web stranice su besplatne za humanitarne udruge i neprofitne organizacije humanitarnog karaktera.

Ova aplikacija je reboot nekad popularnog chat portala koji je dobio nekoliko novih feature-a. To je svojevrsno ljubavno pismo pop-kulturi i pre-smartphone internetu. Svako dobro i guštajte.</em></p>
                        <p>By <a href="https://ivankoperisic.from.hr/" target="_blank" className="my-text">Ivanko Perišić</a> (<a target="_blank" className="my-text" href="https://wordpresshosting.hr">Hyperion WordPress Hosting</a>)</p>
                    </div>
                </div>
                <p></p>
                <p><strong>UPOZORENJE: Ovaj servis je napravljen sa dobrim namjerama ali ga koristite na vlastitu odgovornost! Ne preuzimam nikakvu odgovornost za štetu ili bilo kakve posljedice nastale korištenjem ovog servisa.</strong></p>
                <p></p>
                <p>Sve upite slati na: <a href="mailto:info@nethr.chat">info@nethr.chat</a></p>
                <p></p>
                <div >
                    <p className="welcome-msg-title"><strong>Mogućnosti</strong></p>
                    <div className="app-cards">

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide4.webp" /></div>
                            <div className="app-card-content">
                                <span>Matchmaking</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Dating & friend finder. Pronađite osobu koja traži isto što i vi.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide1.webp" /></div>
                            <div className="app-card-content">
                                <span>Korisnici</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Upoznajte korisnike surfajući profilima.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide2.webp" /></div>
                            <div className="app-card-content">
                                <span>Korinički račun</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Nadzorna ploča gdje možete uređivati svoje administrativne podatke ili svoj profil.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide3.webp" /></div>
                            <div className="app-card-content">
                                <span>Multiplayer</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Natjecanje u tipkanju. Pobjednik osvaja winner boju, 100% energije i ne može ga se ocrnit ili spasit.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide5.webp" /></div>
                            <div className="app-card-content">
                                <span>Shoutbox</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Kupujete, prodajete, iznajmljujete, nudite, tražite? Kreirajte svog shoutbota koji će spamat chat.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide6.webp" /></div>
                            <div className="app-card-content">
                                <span>Radio</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Playlista omiljenih YouTube video naših članova. Dodajte pjesmu ili vama zanimljivi YT video.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide7.webp" /></div>
                            <div className="app-card-content">
                                <span>nethr.chat wars sezone</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Korisnik se može ocrnit ili obranit i tako gubi ili dobiva energiju. Na kraju sezone se kreće ispočetka.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide9.webp" /></div>
                            <div className="app-card-content">
                                <span>News</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Uz nethr.chat robo news plus korisničke vijesti možete napraviti svoju temu koja vas zanima ili podjelite neku vijest koju želite komentirati.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide10.webp" /></div>
                            <div className="app-card-content">
                                <span>Uskršnje jaje</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Ako pronađete i pritisnete točkicu negdje na ekranu osvajate "winner" boju.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide11.webp" /></div>
                            <div className="app-card-content">
                                <span>Offline eventovi</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">nethr.chat organizira eventove na kojima možete upoznati chatere u stvarnom životu.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide12.webp" /></div>
                            <div className="app-card-content">
                                <span>Vježbaj tipkanje</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Redovno vježbaj tipkanje da bi mogao pobjeđivati u multiplayeru.</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="app-card margin-bottom">
                            <div className="app-card-img-wrap"><img src="slides/slide13.webp" /></div>
                            <div className="app-card-content">
                                <span>Leveli</span>
                                <div class="app-card-buttons">
                                    <div className="row app-card-border">
                                        <div class="app-card__subtext">Pobjedama u multiplayeru osvajate veći level. Max level je 99.</div>


                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <p className="welcome-msg-title"><strong>Korišteni resursi</strong></p>
                <ul>
                    <li>Dizajn: <a target="_blank" href="https://codepen.io/TurkAysenur">TurkAysenur</a></li>
                    <li>Ikone: <a target="_blank" href="https://icons8.com/">Icons8</a></li>
                    <li>Avatari: <a target="_blank" href="https://api.multiavatar.com/">Multiavatar API</a></li>
                    <li>Emoji: <a target="_blank" href="https://www.npmjs.com/package/emoji-picker-react">Emoji Picker React</a></li>
                    <li>Prijevod: <a target="_blank" href="https://www.bing.com/translator">Microsoft Bing Translation</a></li>
                    <li>Animirana pozadina: <a target="_blank" href="https://codepen.io/theprabir/pen/poGNBzO">Animated CSS Background</a></li>
                    <li>Font1: <a target="_blank" href="https://www.1001fonts.com/poppins-font.html">Poppins</a></li>
                    <li>Font2: <a target="_blank" href="https://www.dafont.com/sectar.font">Sectar</a></li>
                </ul>
                <p className="welcome-msg-title"><strong>Korištene tehnologije</strong></p>
                <div className="row multiline">
                    <a target="_blank" href="https://developer.mozilla.org/en-US/"><img className="tech-logo" src="/logotipi/fe.jpg" /></a>
                    <a target="_blank" href="https://nodejs.org/en"><img className="tech-logo" src="/logotipi/node.jpg" /></a>
                    <a target="_blank" href="https://expressjs.com/"><img className="tech-logo" src="/logotipi/express.jpg" /></a>
                    <a target="_blank" href="https://www.mysql.com/"><img className="tech-logo" src="/logotipi/mysql.jpg" /></a>
                    <a target="_blank" href="https://pptr.dev/"><img className="tech-logo" src="/logotipi/puppeteer.jpg" /></a>
                    <a target="_blank" href="https://socket.io/"><img className="tech-logo" src="/logotipi/socketio.jpg" /></a>
                    <a target="_blank" href="https://sequelize.org/"><img className="tech-logo" src="/logotipi/sequelize.jpg" /></a>
                    <a target="_blank" href="https://axios-http.com/"><img className="tech-logo" src="/logotipi/axios.jpg" /></a>
                    <a target="_blank" href="https://react.dev/"><img className="tech-logo" src="/logotipi/react.jpg" /></a>
                    <a target="_blank" href="https://sass-lang.com/"><img className="tech-logo" src="/logotipi/sass.jpg" /></a>
                    <a target="_blank" href="https://splidejs.com/"><img className="tech-logo" src="/logotipi/splide.jpg" /></a>
                </div>
                <p className="welcome-msg-title"><strong>Posebna pohvala:</strong> Damiru Sobolu i njegovom Iskon internetu</p>
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {uvjetiContent()}
                </div>

            </div>
        </div>
    )
}

export default UvjetiKoristenja